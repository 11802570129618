const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6915523",
    space_js: "//tapbfdh.htinfotech.cn/source/jed-mn/bbt/production/w.js"
  }
]

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6915524",
    space_js: "//tapbfdh.htinfotech.cn/production/k/production/fen/static/o/ccub.js"
  }
]

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6915525",
    space_js: "//tapbfdh.htinfotech.cn/production/lg/f_o/p/openjs/ddvd.js"
  }
]

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6915526",
    space_js: "//tapbfdh.htinfotech.cn/production/mh_gpq_eew_g.js"
  }
]

export default {
  HomeInter: Inter,
  HomeDialog: Native1,
  HomeNative: Native2,
  HomeBanner: Native3,
  WorldListNative: Native2,
  WorldListBanner: Native3,
  WorldDetailNative: Native2,
  WorldDetailBanner: Native3,
  WorldDetailDialog: Native1,
  TravelListNative: Native2,
  TravelListBanner: Native3,
  TravelDetaitNative: Native2,
  TravelDetailBanner: Native3,
  TravelDetailInter: Inter,
  TopicNative: Native2,
  TopicBanner: Native3,
  AnswerInter: Inter
}