import { request } from "@/utils/service";
import { useUserStore } from "@/store/user";

function baseParams(isGzp: boolean | undefined = true) {
  const { token, uuid } = useUserStore();
  const params: IBaseParams = { user_id: uuid };

  if (token) params.access_token = token;

  if (isGzp) params.gzp = 1;

  return params;
}

// 获取城市景区列表
export const getTravelcityList = (params: ITravelcityParams) => {
  return request<ListData<ITravelcity>>({
    url: "/api/travelcity/list",
    method: "post",
    data: { ...baseParams(), ...params }
  });
};

// 获取城市信息
export const getCitys = () => {
  return request<string[]>({
    url: "/api/travelcity/citys",
    method: "get"
  });
};

// 获取景区列表
export const getTravelplaceList = (params: ITravelplaceParams) => {
  return request<ListData<ITravelplace>>({
    url: "/api/travelplace/list",
    method: "post",
    data: { ...baseParams(), ...params, gzp: 0 }
  });
};

// 获取推荐内容
export const getTravelplaceReclist = (params: Omit<ITravelplaceParams, "place">) => {
  return request<ListData<ITravelplace>>({
    url: "/api/travelplace/reclist",
    method: "post",
    data: { ...baseParams(), ...params }
  });
};

// 用户收藏行为
interface IUserObjecttParams {
  object_id: string;
  object_type: "travel_city_place";
  type: 0 | 1 | "0" | "1";
}
export const userobjectcollectAdd = (params: IUserObjecttParams) => {
  return request<boolean>({
    url: "/api/userobjectcollect/add",
    method: "post",
    data: { ...baseParams(), ...params }
  });
};

export const userobjectlikeAdd = (params: IUserObjecttParams) => {
  return request<boolean>({
    url: "/api/userobjectlike/add",
    method: "post",
    data: { ...baseParams(), ...params }
  });
};
