import { defineStore } from "pinia";
import { randomNumber } from "@/utils";
import { getCitys } from "@/apis/travel";

interface ITravelStore {
  citys: string[] | null;
}

export const useTravelStore = defineStore({
  id: "travel",
  state(): ITravelStore {
    return {
      citys: null
    };
  },
  actions: {
    async getCitys() {
      try {
        const res = await getCitys();

        this.citys = res;
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
});
