import type {RouteRecordRaw} from "vue-router";

const getRedirect = function () {
  if (!process.env.VUE_APP_START) return "/home";
  else if (process.env.VUE_APP_START == "guide") return "/guide";
  else return "/home";
};

export default <RouteRecordRaw[]> [
  {
    path: "/",
    redirect: getRedirect()
  },
  {
    path: "/guide",
    name: "Guide",
    component: () => import("@/views/guide/GuideView.vue"),
    meta: {
      title: APP_NAME,
      hideFixedHome: false,
      spaceKeys: ["Guide_banner", "Guide_native"]
    }
  },
  {
    path: "/home",
    name: "Home",
    component: () => import(/* webpackPrefetch: true  */ "@/views/home/HomeView.vue"),
    meta: {
      title: APP_NAME,
      hideFixedHome: false,
      spaceKeys: ["HomeBanner", "HomeNative", "HomeDialog", "HomeInter"]
    }
  },
  {
    path: "/world",
    redirect: "/world/list",
    children: [
      {
        path: "/world/list",
        name: "WorldList",
        component: () => import(/* webpackPrefetch: true  */ "@/views/world/WorldListView.vue"),
        meta: {
          title: "云逛世界",
          spaceKeys: ["WorldListNative", "WorldListBanner"]
        }
      },
      {
        path: "/world/detail",
        name: "WorldDetail",
        component: () => import(/* webpackPrefetch: true  */ "@/views/world/WorldDetailView.vue"),
        props: (route) => ({ city: route.query.city }),
        meta: {
          title: "云逛世界详情",
          spaceKeys: ["WorldDetailNative", "WorldDetailBanner", "WorldDetailDialog"]
        }
      }
    ]
  },
  {
    path: "/travel",
    redirect: "/travel/list",
    children: [
      {
        path: "/travel/list",
        name: "TravelList",
        component: () => import(/* webpackPrefetch: true  */ "@/views/travel/TravelListView.vue"),
        props: (route) => ({ city: route.query.city }),
        meta: {
          title: "经典必游",
          spaceKeys: ["TravelListNative", "TravelListBanner"]
        }
      },
      {
        path: "/travel/detail",
        name: "TravelDetail",
        props: (route) => ({ id: route.query.id }),
        component: () => import(/* webpackPrefetch: true  */ "@/views/travel/TravelDetailView.vue"),
        meta: {
          title: "景点详情",
          spaceKeys: ["TravelDetaitNative", "TravelDetailBanner", "TravelDetailInter"]
        }
      }
    ]
  },
  {
    path: "/topicDetail",
    name: "TopicDetail",
    component: () => import(/* webpackPrefetch: true  */ "@/views/topic/TopicDetailView.vue"),
    props: (route) => ({ id: route.query.id }),
    meta: {
      title: "看图猜地名",
      spaceKeys: ["TopicNative", "TopicBanner", "AnswerInter"]
    }
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/NotFoundView.vue"),
    meta: {
      title: "404"
    }
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404"
  }
];